module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CTA Manufacturing","short_name":"CTA Manufacturing","lang":"it","start_url":"/it/","background_color":"#35b775","theme_color":"#2196f3","display":"standalone","icon":"src/images/cta-icon.png","cache_busting_mode":"none","localize":[{"start_url":"/en/","lang":"en","name":"CTA Contract Manufacturing","short_name":"CTA Manufacturing"},{"start_url":"/es/","lang":"es","name":"CTA Producción Industrial para Terceros","short_name":"CTA Manufacturing"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
