import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'
import translationIT from './locales/it/translation.json'

// the translations
export const resources = {
  en: {
    lang: 'English',
    translation: translationEN,
  },
  it: {
    lang: 'Italiano',
    translation: translationIT,
  },
  es: {
    lang: 'Español',
    translation: translationES,
  },
}

export const langs = Object.keys(resources)

i18n
  .use(detector)
  .use(initReactI18next) // makes i18n available for all the component
  .init({
    resources,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      // order and from where user language should be detected
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: 'myDomain',
    },
  })

export default i18n
