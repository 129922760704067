import React from 'react';
import i18n from './src/i18n';
import { I18nextProvider } from 'react-i18next';
import { PageContextProvider } from './src/pageContext';

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>;
};

export const onRenderBody = (
  { setHeadComponents, setPostBodyComponents },
  pluginOptions
) => {
  setHeadComponents([
    <script
      defer 
      data-domain="ctamanufacturing.com/0eIUVg" 
      src="https://api.publytics.net/js/script.manual.min.js"
    />,
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.publytics = window.publytics || function() { (window.publytics.q = window.publytics.q || []).push(arguments) };
          publytics('pageview');
        `,
      }}
    />,
    <script
      async
      defer
      src="https://app.visitortracking.com/assets/js/tracer.js"
    />,
    <script
      dangerouslySetInnerHTML={{
        __html: `
          function init_tracer() {
            var tracer = new Tracer({
              websiteId: '46935ab1-d45a-4af1-b953-d00bbcb8e306',
              async: true,
              debug: false
            });
          }
          document.addEventListener('DOMContentLoaded', init_tracer);
        `,
      }}
    />
  ])
}
